<template>
  <button
    v-if="type === 'button'"
    type="button"
    :class="`btn ${variant}`"
  >
    <span>{{ text }}</span>
  </button>

  <button
    v-else-if="type === 'submit'"
    type="submit"
    :class="`btn ${variant}`"
  >
    <span>{{ text }}</span>
  </button>

  <button
    v-else-if="link?.startsWith('@')"
    type="button"
    :class="`btn ${variant}`"
    @click="() => toggleOverlay()"
  >
    <span>{{ text }}</span>
  </button>

  <button
    v-else-if="link?.startsWith('#')"
    type="button"
    :class="`btn ${variant}`"
    @click="() => onButtonClick(link)"
  >
    <span>{{ text }}</span> <Icon v-if="icon" :icon="icon" class="ml-1 h-3 w-3" />
  </button>

  <NuxtLink
    v-else
    :to="link"
    :class="`btn ${variant}`"
    :rel="external ? 'nofollow noopener' : ''"
    :target="external ? '_blank' : ''"
  >
    <span>
      {{ text }} <Icon v-if="external" icon="svgo-arrow-external" class="ml-1 h-3 w-3" />
    </span>
  </NuxtLink>
</template>

<script setup lang="ts">
defineProps<{
  text?: string;
  link?: string;
  icon?: string;
  type?: 'button' | 'submit' | undefined;
  variant?: 'btn--primary' | 'btn--secondary' | 'btn--tertiary' | 'btn--link' | undefined;
  external?: boolean;
}>();

const { toggleOverlay } = useOverlay();
const { $scrollSmoother } = useNuxtApp();

const onButtonClick = (link: string | undefined) => {
  if (!link) return;

  $scrollSmoother.scrollTo(link, true, 'top top');
};
</script>
